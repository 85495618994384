import {scrubEvent} from '@chanzuckerberg/sentry-js-pii-scrubber';
import {RemixBrowser} from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import {startTransition, StrictMode} from 'react';
import {hydrateRoot} from 'react-dom/client';

if (window.ENV?.SENTRY_DSN) {
  Sentry.init({
    beforeSend(event) {
      return scrubEvent(event);
    },
    dsn: window.ENV.SENTRY_DSN,
    environment: window.ENV.DEPLOYMENT_STAGE || 'localhost',
    release: window.ENV.GIT_SHA,
    enableTracing: false,
  });
}

const hydrate = () => {
  startTransition(() => {
    hydrateRoot(
      document,
      <StrictMode>
        <RemixBrowser />
      </StrictMode>,
    );
  });
};

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1);
}
